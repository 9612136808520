.offer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  overflow: hidden;


  .discount-percentage-wrapper {
    position: absolute;
    top: 5vw;
    right: -8vw;
    transform: rotate(45deg);
    font-size: 5vw;
    background: #8f0000;
    width: 40rem;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    .discount-percentage {
      color: white;
      padding: 1rem;
      display: block;
    }
  }

  .content-box {
    width: 40%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: left;
    .offer-name {
      font-size: 7rem;
      line-height: 1;
    }
    .offer-price-official {
      font-size: 9rem;
      text-decoration: line-through;
      color: #8f0000;
    }
    .offer-price-discount {
      font-size: 9rem;
    }
  }
  .offer-image {
    width: 55%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 80%;
  }
}

@keyframes pulse {
    0% {
      opacity: 0;
    } 10% {
        opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
        opacity: 0;
    }
  }